<template>
  <div class="user_center_page_container">
    <user-card :info="userInfo"></user-card>
    <exam-info-card :info="examInfo"></exam-info-card>
    <password-card></password-card>
  </div>
</template>
<script>
/* eslint-disable */
import { connectState } from "../../utils/wyUtil";
import UserCard from "./components/UserCard.vue";
import ExamInfoCard from "./components/ExamInfoCard.vue";
import PasswordCard from "./components/PasswordCard.vue";

export default {
  components: {
    PasswordCard,
    ExamInfoCard,
    UserCard,
  },
  data() {
    return {
      localUserInfo: {},
    };
  },
  computed: {
    ...connectState("login", ["userInfo", "examInfo"]),
  },
  created() {
    this.$store.dispatch("login/fetchUserInfo");
    this.userExamInfo();
    this.getExamDates();
  },
  methods: {
    userExamInfo() {
      this.$store.dispatch("login/fetchExamInfo");
    },
    getUserInfo() {
      this.$store.dispatch("login/fetchUserInfo");
    },
    getExamDates() {
      this.$store.dispatch("login/queryExamDates", {
        time: new Date(),
        examType: 2,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.user_center_page_container {
  .user_avatar_card {
  }
}
</style>
