<template>
  <div class="exam_score_card_container" :class="noBorder?'no_border':null">
    <p class="sub_title">{{title}}</p>
    <ul class="list_container">
      <li v-for="(item,index) in list" :key="item.key">
        <div>
          <p v-if="!canEdit" class="num">{{item.value}}</p>
          <el-select v-else v-model="item.value" placeholder="" class="mini_selector">
            <el-option
              v-for="num in numOptions"
              :key="num"
              :value="num">
            </el-option>
          </el-select>
          <p class="text">{{item.label}}</p>
        </div>
        <div class="line" v-show="index!==list.length-1"></div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'scoreCard',
  props: {
    title: String,
    list: {
      type: Array,
      default: () => ([]),
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numOptions: [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9],
    };
  },

};
</script>
<style lang="scss" scoped>
  .exam_score_card_container{
    width:480px;
    height:184px;
    padding:25px 0;
    margin-right:2px;
    background:#FFF;
    .sub_title{
      margin-left:35px;
      height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #CBCED7;
      line-height: 22px;
      margin-bottom:30px;
    }
    .list_container{
      display:flex;
      flex-direction: row;
      li{
        width:120px;
        text-align: center;
        position: relative;
        &:nth-child(1){
          width:97px;
        }
        &:nth-last-child(1){
          width:140px;
        }
        .line{
          position:absolute;
          right:0;
          top:10px;
          height:47px;
          width:1px;
          background:#CACDD6;
        }
        .num{
          height: 41px;
          font-size: 34px;
          font-family: BebasNeue;
          color: #131B33;
          line-height: 41px;
          letter-spacing: 3px;
          margin-bottom:8px;
        }
        .text{
          height: 20px;
          font-size: 14px;
          font-weight: bold;
          color: #CACDD6;
          line-height: 20px;

        }
      }
      .mini_selector{
        width:70%;
        margin-bottom:8px;
      }

    }
  }
  .no_border{
    margin-right:0;
  }

</style>
