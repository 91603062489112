<template>
  <div class="password_input_wrapper">
    <span class="label">{{info.label}}</span>
    <el-input
      :type="show?'text':'password'"
      class="inline_input"
      v-model="value"
      :placeholder="info.placeholder"
      @input="handleInput"
    >
      <template #suffix>
        <i class="eye" :class="show?'open':null" @click="show=!show"></i>
      </template>
    </el-input>
  </div>
</template>
<script>
export default {
  name: 'passwordInput',
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      value: '',
      show: false,
    };
  },
  methods: {
    handleInput(val) {
      this.$emit('change', { key: this.info.key, value: val });
    },
  },

};
</script>
<style lang="scss" >
  .password_input_wrapper{
    display:flex;
    flex-direction: row;
    align-items: center;
    height: 28px;
    margin-bottom:10px;
    .label{
      flex:none;
      width:80px;
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #A0A2AB;
      line-height: 22px;
    }
    .input_box{
      width: 200px;
      height: 28px;
      border-radius: 14px;
      border: 1px solid #CACDD6;
      padding:3px 10px;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .password_tip{
        flex:1;
        padding:0 10px;
        color:#A0A2AB;
        font-size:14px;
        font-weight: 600;
      }
    }
    .inline_input{
      width:200px;
    }
    .el-input__inner{
      width: 200px;
      height: 28px;
      border-radius: 14px;
      border: 1px solid #CACDD6;
    }
    .el-input__suffix{
      right:10px;
      top:3px;
    }
    .eye{
      display:inline-block;
      vertical-align: middle;
      flex:none;
      width:13px;
      height:6px;
      background:url("../../../assets/img/eye_close.png") 0 0 no-repeat;
      background-size:100% 100%;
      cursor: pointer;
    }
    .open{
      height:8px;
      background:url("../../../assets/img/eye_open.png") 0 0 no-repeat;
      background-size:100% 100%;
    }
  }

</style>
