<template>
  <div class="user_info_card_container">
    <div class="left_part">
      <img :src="userInfo.avatar" alt="" class="avatar_container" />
      <p class="user_name">{{ userInfo.userName }}</p>
    </div>
    <div class="right_part">
      <div>
        <p class="title">个人信息</p>
        <text-form :list="list" :can-edit="canEditUserInfo"></text-form>
      </div>
      <div class="btn" v-if="!canEditUserInfo" @click="canEditUserInfo = true">
        编辑
      </div>
      <div v-else>
        <div class="btn plain_btn inline" @click="canEditUserInfo = false">
          取消
        </div>
        <div class="btn primary_plain inline" @click="handleSave">保存</div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { dateFormat } from "@/utils/timezone";
import TextForm from "../../../components/TextForm.vue";

export default {
  name: "userCard",
  components: { TextForm },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: [],
      userInfo: {},
      canEditUserInfo: false,
    };
  },
  watch: {
    info(val) {
      if (val) {
        this.userInfo = val;
        this.list = this.initDataList(
          {
            sex: "性别：",
            birthday: "生日：",
            // currentLocation: "现居地：",
            nameEn: "英文名：",
            qqNumber: "QQ号：",
          },
          val
        );
      }
    },
  },
  methods: {
    initDataList(obj, data) {
      return Object.keys(obj).map((key) => {
        const params = { key, label: obj[key], value: data[key] };
        if (key === "sex") {
          params.type = "radio";
          params.options = [
            // sex (integer, optional): 性别 1-男、2-女、3保密
            { label: 1, value: "男" },
            { label: 2, value: "女" },
            { label: 3, value: "保密" },
          ];
        }
        if (key === "birthday") {
          params.type = "date";
        }
        return params;
      });
    },
    handleSave() {
      const payload = this.resetListData();
      const regQQ = /[1-9][0-9]{4,14}/;
      const reg = /^[A-Za-z\s]+$/;
      if (payload.qqNumber && !regQQ.test(payload.qqNumber)) {
        this.$message.error("QQ号不合法，请重新输入");
        return false;
      }
      if (payload.nameEn && !reg.test(payload.nameEn)) {
        this.$message.error("英文名不合法，请重新输入");
        return false;
      }
      this.$store.dispatch("login/updateUserInfo", {
        ...payload,
        callback: () => {
          this.$message({ type: "success", message: "保存成功！" });
          this.$emit("refresh");
          this.canEditUserInfo = false;
        },
      });
      return true;
    },
    resetListData() {
      let obj = {};
      this.list = this.list.map((item, index) => {
        if (item.key === "birthday") {
          this.list[index].value = item.value ? dateFormat(item.value) : null;
        }
        obj = { ...obj, [item.key]: this.list[index].value };
        return item;
      });
      return obj;
    },
  },
};
</script>
<style lang="scss" scoped>
.user_info_card_container {
  width: 100%;
  height: 292px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  .left_part {
    flex: none;
    width: 208px;
    border-right: 2px solid #f8f8f8;
    .avatar_container {
      width: 86px;
      height: 86px;
      max-width: 100%;
      border-radius: 50%;
      overflow: hidden;
      margin: 85px 60px 15px;
    }
    .user_name {
      font-size: 16px;
      font-weight: 600;
      color: #6d7278;
      line-height: 22px;
      text-align: center;
    }
  }
  .right_part {
    flex: 1;
    padding: 25px 25px 5px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title {
      height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #131b33;
      line-height: 22px;
      margin-left: 20px;
      margin-bottom: 10px;
    }
    .btn {
      width: 88px;
      height: 35px;
      background: #3377ff;
      border-radius: 3px;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      line-height: 35px;
      text-align: center;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .plain_btn {
      background: rgba(203, 206, 215, 0.16);
      color: #cacdd6;
    }
    .inline {
      display: inline-block;
      margin-left: 10px;
    }
    .primary_plain {
      background: rgba(51, 119, 255, 0.16);
      color: #3377ff;
    }
  }
}
</style>
