<template>
  <div class="user_password_card_container">
    <p class="title">修改密码</p>
    <div class="password_input_box" v-if="!canEdit">
      <i></i>
      <p class="password_tip">********</p>
      <div class="edit_btn" @click="handleEdit">修改</div>
    </div>
    <div v-else>
      <password-input
        v-for="item in passwordsList"
        :key="item.key"
        @change="valueChange"
        :info="item"></password-input>
      <div>
        <div class="btn plain_btn inline" @click="canEdit=false">取消</div>
        <div class="btn primary_plain inline" @click="handleSave">保存</div>
      </div>
    </div>

  </div>
</template>
<script>
import PasswordInput from './PasswordInput.vue';

export default {
  name: 'passwordCard',
  components: { PasswordInput },
  data() {
    return {
      userPassword: '',
      canEdit: false,
      passwordsList: [
        {
          key: 'oldPassword',
          value: '',
          label: '旧密码：',
          placeholder: '请输入旧密码',
        }, {
          key: 'newPassword',
          value: '',
          label: '新密码：',
          placeholder: '请输入新密码',
        }, {
          key: 'confirmPwd',
          value: '',
          label: '确认密码：',
          placeholder: '请再次输入新密码',
        },
      ],
      form: {},

    };
  },
  methods: {
    handleEdit() {
      this.canEdit = true;
      const el = document.querySelector('#passwordEl');
      if (el) {
        el.focus();
      }
    },
    handleSave() {
      const { oldPassword, newPassword, confirmPwd } = this.form;
      if (!oldPassword) {
        this.$message.error('旧密码不能为空');
        return false;
      }
      if (!newPassword) {
        this.$message.error('请填写新密码');
        return false;
      }
      console.log('----', newPassword, confirmPwd);
      if (newPassword !== confirmPwd) {
        this.$message.error('确认密码与新密码不一致，请检查');
        return false;
      }
      this.$store.dispatch('login/updatePassword', {
        ...this.form,
        callback: () => {
          this.$message({ type: 'success', message: '操作成功' });
          this.canEdit = false;
        },
      });
      return true;
    },
    valueChange({ key, value }) {
      this.form[key] = value;
    },
  },

};
</script>
<style lang="scss" scoped>
  .user_password_card_container{
    background: #FFFFFF;
    border-radius: 5px;
    margin-bottom:30px;
    padding:25px 35px;
    .title{
      width: 64px;
      height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #131B33;
      line-height: 22px;
      margin-bottom:25px;
    }
    .password_input_box{
      width: 200px;
      height: 28px;
      border-radius: 14px;
      border: 1px solid #CACDD6;
      padding:3px 5px;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      i{
        width:11px;
        height:13px;
        background:url("../../../assets/img/icon_password.png") 0 0 no-repeat;
        background-size:100% 100%;
        margin-left:5px;
        margin-right:5px;
      }
      input{
        border:none;
        padding-left:5px;
        outline: none;
        width:130px;
      }
      .edit_btn{
        height: 17px;
        font-size: 12px;
        font-weight: bold;
        color: #CACDD6;
        line-height: 17px;
        cursor: pointer;
        &:hover{
          color:#3377FF;
        }

      }
      .password_tip{
        width:130px;
        height: 28px;
        line-height: 30px;
        color:#CACDD6;
        font-weight:600;

      }

    }
    .btn{
      width: 88px;
      height: 35px;
      background: #3377FF;
      border-radius: 3px;
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 35px;
      text-align: center;
      cursor: pointer;
      margin-top:10px;
      &:hover{
        opacity:0.8;
      }
    }
    .plain_btn{
      background:rgba(203, 206, 215, 0.16);
      color:#CACDD6;
    }
    .inline{
      display:inline-block;
      margin-right: 20px;
    }
    .primary_plain{
      background:rgba(51, 119, 255, 0.16);
      color:#3377FF;
    }

  }

</style>
