<template>
  <div class="user_exam_info_card">
    <div class="top_part">
      <div>
        <p class="exam_title">考试信息</p>
        <text-form :list="list" :can-edit="canEdit"></text-form>
      </div>
      <div class="btn" v-if="!canEdit" @click="canEdit=true">编辑</div>
      <div v-else>
        <div class="btn plain_btn inline" @click="canEdit=false">取消</div>
        <div class="btn primary_plain inline" @click="handleSave">保存</div>
      </div>
    </div>
    <div class="bottom_part">
      <score-card
        key="score"
        title="上次成绩："
        :list="scoreList"
        :can-edit="canEdit"
      ></score-card>
      <score-card
        key="target_score"
        title="目标成绩："
        :list="targetList"
        :no-border="true"
        :can-edit="canEdit"
      ></score-card>
    </div>

  </div>
</template>
<script>
/*eslint-disable*/
import { dateFormat } from '@/utils/timezone';
import TextForm from '../../../components/TextForm.vue';
import ScoreCard from './ScoreCard.vue';

export default {
  name: 'examInfoCard',
  components: { ScoreCard, TextForm },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: [ {
        key: 'examDate',
        label: '考试日期：',
        value: "暂无",
        type: 'calendar',
        examId: "",
      },],
      scoreList: this.initDataList({
        oralGrade: '口语',
        writeGrade: '写作',
        listenGrade: '听力',
        readGrade: '阅读',
      }, {}),
      targetList: this.initDataList({
        targetOralGrade: '口语',
        targetWriteGrade: '写作',
        targetListenGrade: '听力',
        targetReadGrade: '阅读',
      }, {}),
      canEdit: false,
    };
  },
  watch: {
    info(val) {
      if(val){
        this.list = [
          {
            key: 'examDate',
            label: '考试日期：',
            value: val.examDate,
            type: 'calendar',
            examId: val.examDateId,
          },
        ];
        this.scoreList = this.initDataList({
          oralGrade: '口语',
          writeGrade: '写作',
          listenGrade: '听力',
          readGrade: '阅读',
        }, val);
        this.targetList = this.initDataList({
          targetOralGrade: '口语',
          targetWriteGrade: '写作',
          targetListenGrade: '听力',
          targetReadGrade: '阅读',
        }, val);
      }
    },
  },
  methods: {
    initDataList(obj, data) {
      return Object.keys(obj).map((key) => ({
        key, label: obj[key], value: data[key] || 0,
      }));
    },
    handleSave() {
      this.list[0].value=dateFormat(this.list[0].value)
      const { value, examId } = this.list[0];
      const dateObj = { examDate: value, examDateId: examId };
      const scoreObj = this.reFormatData(this.scoreList);
      const targetObj = this.reFormatData(this.targetList);
      const payload = this.calcAverage({ ...dateObj, ...scoreObj, ...targetObj });
      this.$store.dispatch('login/updateUserExamInfo', { ...payload,callback:()=>{
        this.$message({type:'success',message:"操作成功"})
          this.canEdit = false;
        } });
    },
    calcAverage(obj) {
      const {
        listenGrade, oralGrade, readGrade, writeGrade,
        targetOralGrade, targetReadGrade, targetWriteGrade, targetListenGrade,
      } = obj;
      const averageGrade = parseFloat(((listenGrade + oralGrade + writeGrade + readGrade) / 4).toFixed(2));
      const targetAverageGrade = parseFloat(((targetOralGrade + targetReadGrade + targetWriteGrade + targetListenGrade) / 4)
        .toFixed(2));
      return { ...obj, averageGrade, targetAverageGrade };
    },
    reFormatData(list) {
      let obj = {};
      list.forEach((item) => {
        obj = { ...obj, [item.key]: item.value };
      });
      return obj;
    },

  },
};
</script>
<style lang="scss" scoped>
  .user_exam_info_card{
    border-radius: 6px;
    margin-bottom:10px;
    .top_part{
      background:#FFF;
      padding:25px 35px 0 15px;
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #F8F8F8;
      .exam_title{
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: #131B33;
        line-height: 22px;
        margin-left:20px;
      }
      .btn{
        width: 88px;
        height: 35px;
        background: #3377FF;
        border-radius: 3px;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 35px;
        text-align: center;
        cursor: pointer;
        &:hover{
          opacity:0.8;
        }
      }
      .plain_btn{
        background:rgba(203, 206, 215, 0.16);
        color:#CACDD6;
      }
      .inline{
        display:inline-block;
        margin-left: 10px;
      }
      .primary_plain{
        background:rgba(51, 119, 255, 0.16);
        color:#3377FF;
      }
    }
    .bottom_part{
      display:flex;
      flex-direction: row;
      align-items: center;
    }

  }

</style>
